import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  ClickAwayListener,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { AppContext } from "../../App";
import ContentContainer from "../ContentContainer";
import "./TodoItem.css";

interface ITodoItemProps {
  todoKey: number;
  originalTodoText?: string;
  storedCheck?: boolean;
  removeTodo: (key: number) => void;
  updateTodo: (
    todoKey: number,
    checked?: boolean,
    updatedTodoText?: string
  ) => void;
}

const TodoItem = ({
  todoKey,
  removeTodo,
  updateTodo,
  storedCheck,
  originalTodoText,
}: ITodoItemProps) => {
  const [checked, setChecked] = useState(storedCheck || false);
  const [hideInput, setHideInput] = useState(originalTodoText ? true : false);
  const [todoText, setTodoText] = useState(originalTodoText || "");
  const { isDarkMode } = useContext(AppContext);

  const selectText = (
    event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>
  ): void => {
    event.currentTarget.select();
  };

  return (
    <ContentContainer
      styles={{
        display: "flex",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <Checkbox
        key={todoKey}
        checked={checked}
        style={{
          display: "flex",
          float: "left",
          color: isDarkMode ? undefined : "white",
        }}
        onClick={() => {
          setChecked((value) => !value);
          updateTodo!(todoKey, !checked);
        }}
        onTouchStart={() => {
          setChecked((value) => !value);
          updateTodo!(todoKey, !checked);
        }}
      />
      {hideInput ? (
        <div
          onClick={() => {
            setHideInput(false);
          }}
          onTouchStart={() => {
            setHideInput(false);
          }}
          style={{
            display: "flex",
            width: 238,
            overflow: "break-word",
            cursor: "pointer",
            textDecorationLine: checked ? "line-through" : undefined,
            color: isDarkMode ? undefined : "white",
          }}
        >
          {todoText}
        </div>
      ) : (
        <ClickAwayListener
          onClickAway={() => setHideInput(todoText.length > 0)}
        >
          <TextField
            variant="standard"
            className={isDarkMode ? "TodoItemDarkMode" : "TodoItem"}
            style={{
              width: 238,
              color: isDarkMode ? undefined : "white",
            }}
            value={todoText || originalTodoText}
            onChange={(e) => {
              setTodoText(e.target.value);
              updateTodo!(todoKey, checked, e.target.value);
            }}
            inputProps={{
              onDoubleClick: selectText,
            }}
            onKeyDown={(e) =>
              e.key === "Enter" ? setHideInput(todoText.length > 0) : null
            }
          />
        </ClickAwayListener>
      )}
      <IconButton
        id="basic-button"
        style={{ color: isDarkMode ? undefined : "white" }}
        onTouchStart={() => removeTodo(todoKey)}
        onClick={() => removeTodo(todoKey)}
      >
        <CloseIcon />
      </IconButton>
    </ContentContainer>
  );
};

export default TodoItem;
