import Add from "@mui/icons-material/Add";
import DailiesIcon from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Clear";
import CounterIcon from "@mui/icons-material/Filter1";
import TodoIcon from "@mui/icons-material/FormatListBulleted";
import GoalIcon from "@mui/icons-material/SportsScore";
import NoteIcon from "@mui/icons-material/StickyNote2";
import TimerIcon from "@mui/icons-material/Timer";
import { Tooltip } from "@mui/material";
import * as ls from "local-storage";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  ChildButton,
  Directions,
  FloatingMenu,
  MainButton,
} from "react-floating-button-menu";
import { hotjar } from "react-hotjar";
import { useTracking } from "react-tracking";
import { AppContext } from "../App";
import { componentTypes, MyStyleSheet } from "../models";
import ContentContainer from "./ContentContainer";
import CounterComponent from "./Counter/CounterComponent";
import GoalComponent from "./Goal/GoalComponent";
import { DailiesComponent } from "./Habit/Dailies";
import NotesComponent from "./Notes/NotesComponent";
import TimerComponent from "./Timer/TimerComponent";
import TodoComponent from "./Todo/TodoComponent";

interface AddComponentProps {
  addComponent: (component: JSX.Element) => void;
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
}

const AddComponentButton = ({
  addComponent,
  removeComponent,
}: AddComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);

  const styles = stylesProvider(isDarkMode);
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (!hotjar.initialized()) {
      hotjar.initialize(3253779, 6);
    }
  }, []);

  const addButton = useMemo((): JSX.Element => {
    return (
      <FloatingMenu
        slideSpeed={300}
        direction={Directions.Up}
        spacing={8}
        isOpen={isOpen}
      >
        <Tooltip title="Add Component" placement="left">
          <div onMouseEnter={() => setIsOpen(true)}>
            <MainButton
              iconResting={
                <Add
                  style={{
                    fontSize: 20,
                    color: isDarkMode ? "black" : "white",
                  }}
                />
              }
              iconActive={
                <Close
                  style={{
                    fontSize: 20,
                    color: isDarkMode ? "black" : "white",
                  }}
                />
              }
              background={isDarkMode ? "white" : "black"}
              onClick={() => setIsOpen((value) => !value)}
              size={56}
            />
          </div>
        </Tooltip>

        <ChildButton
          icon={
            <Tooltip title="Counter" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <CounterIcon
                  style={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={"#83490b"}
          size={40}
          onClick={() => {
            const key = Math.random();
            addComponent(
              <CounterComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
              />
            );

            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `counter_${key}`,
            ]);
          }}
        />
        <ChildButton
          icon={
            <Tooltip title="Todo" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <TodoIcon
                  style={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={"#4b2202"}
          size={40}
          onClick={() => {
            const key = Math.random();
            addComponent(
              <TodoComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
              />
            );
            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `todo_${key}`,
            ]);
          }}
        />
        <ChildButton
          icon={
            <Tooltip title="Timer" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <TimerIcon
                  style={{
                    position: "relative",
                    top: 1,
                    fontSize: 25,
                    color: "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={"#025582"}
          size={40}
          onClick={() => {
            const key = Math.random();
            hotjar.identify("UserID", { userProperty: "00000001" });
            hotjar.event("Add Timer");
            hotjar.stateChange("mindfulspace.app");

            addComponent(
              <TimerComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
              />
            );

            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `timer_${key}`,
            ]);
          }}
        />
        <ChildButton
          icon={
            <Tooltip title="Note" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <NoteIcon
                  style={{
                    fontSize: 25,
                    position: "relative",
                    top: 3,
                    color: "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={"#5d5d0599"}
          size={40}
          onClick={() => {
            const key = Math.random();
            addComponent(
              <NotesComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
              />
            );
            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `notes_${key}`,
            ]);
          }}
        />
        <ChildButton
          icon={
            <Tooltip title="Goal" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <GoalIcon
                  style={{
                    fontSize: 25,
                    position: "relative",
                    top: 3,
                    color: isDarkMode ? "black" : "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={isDarkMode ? "white" : "black"}
          size={40}
          onClick={() => {
            const key = Math.random();
            addComponent(
              <GoalComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
              />
            );
            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `goal_${key}`,
            ]);
          }}
        />
        <ChildButton
          icon={
            <Tooltip title="Dailies" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <DailiesIcon
                  style={{
                    fontSize: 25,
                    position: "relative",
                    top: 3,
                    color: "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={"#225e37"}
          size={40}
          onClick={() => {
            const key = Math.random();
            addComponent(
              <DailiesComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
              />
            );
            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `dailies_${key}`,
            ]);
          }}
        />
        {/* <ChildButton
          icon={
            <Tooltip title="Habit Tracker" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <HabitIcon
                  style={{
                    fontSize: 25,
                    position: "relative",
                    top: 3,
                    color: isDarkMode ? "black" : "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={isDarkMode ? "white" : "black"}
          size={40}
          onClick={() => {
            const key = Math.random();
            addComponent(
              <HabitTrackerComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
                onHandleTodayDone={() => false}
              />
            );
            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `tracker_${key}`,
            ]);
          }}
        /> */}
        {/* <ChildButton
          icon={
            <Tooltip title="Gamify" placement="left">
              <div onClick={() => setIsOpen(false)}>
                <SportsEsportsIcon
                  style={{
                    fontSize: 25,
                    position: "relative",
                    top: 3,
                    color: "white",
                  }}
                />
              </div>
            </Tooltip>
          }
          background={"#5f064a"}
          size={40}
          onClick={() => {
            const key = Math.random();
            addComponent(
              <GamifyComponent
                key={key}
                componentKey={key}
                removeComponent={removeComponent}
              />
            );
            ls.set(`storedComponents`, [
              ...ls.get<string[]>(`storedComponents`),
              `gamify_${key}`,
            ]);
          }}
        /> */}
      </FloatingMenu>
    );
  }, [addComponent, isDarkMode, isOpen, removeComponent]);

  return (
    <ContentContainer styles={styles.button}>{addButton}</ContentContainer>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    button: {
      width: "fit-content",
      height: "5%",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      display: "flex",
      cursor: "pointer",
      position: "absolute",
      top: "92%",
      right: 20,
    },
  };
};

export default AddComponentButton;
