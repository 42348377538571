import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as ls from "local-storage";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTracking } from "react-tracking";
import AddComponentButton from "./components/AddComponentButton";
import ContentContainer from "./components/ContentContainer";
import CounterComponent from "./components/Counter/CounterComponent";
import DarkModeToggleButton from "./components/DarkModeToggleButton";
import GamifyComponent from "./components/Gamify/GamifyComponent";
import GoalComponent from "./components/Goal/GoalComponent";
import { DailiesComponent } from "./components/Habit/Dailies";
import NotesComponent from "./components/Notes/NotesComponent";
import TimerComponent from "./components/Timer/TimerComponent";
import TodoComponent from "./components/Todo/TodoComponent";
import {
  componentTypes,
  ICounter,
  IDailies,
  IGamify,
  IGoal,
  INotes,
  ITimer,
  ITodo,
  MyStyleSheet,
} from "./models";
// import AddIcon from "@mui/icons-material/Add";

export const AppContext = React.createContext({
  isDarkMode: false,
  storedData: Array<string>(),
});

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [componentsList, setComponentsList] = useState<JSX.Element[]>([]);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const initialLoad = useRef(true);
  const userFirstSession = ls.get("user");
  const storedComponents = ls.get<string[]>("storedComponents");
  const { Track } = useTracking();

  enum removeAll {
    removeAll,
  }

  const styles = stylesProvider(isDarkMode);
  const turnLightsOff = () => {
    setIsDarkMode((value) => !value);
  };

  useEffect(() => {
    if (storedComponents === null) ls.set<string[]>("storedComponents", []);
    if (userFirstSession === null) ls.set<boolean>("user", true);
  }, [storedComponents, userFirstSession]);

  const addComponent = useCallback((component: JSX.Element) => {
    setComponentsList((prevComponentList) => [...prevComponentList, component]);
  }, []);

  const removeComponent = useCallback(
    (componentKey: number | removeAll, componentType: componentTypes) => {
      setComponentsList((PrevComponents) =>
        PrevComponents.filter((x) =>
          componentKey === removeAll.removeAll
            ? x.key === "removeAll"
            : x.props?.componentKey !== componentKey
        )
      );
      ls.set(
        "storedComponents",
        storedComponents.filter((x) =>
          componentKey === removeAll.removeAll
            ? false
            : x !== `${componentType}_${componentKey}`
        )
      );
    },
    [removeAll.removeAll, storedComponents]
  );

  const alertDialog = useCallback(() => {
    return (
      <Dialog open={showDialog}>
        <DialogTitle style={{ textAlign: "center" }}>
          {"Are you sure you want to clear all?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" align="center">
            This will clear all the components on this page, turning it blank.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              removeComponent(removeAll.removeAll, componentTypes.all);
              setShowDialog(false);
            }}
          >
            Yes, clear it all
          </Button>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }, [removeAll.removeAll, removeComponent, showDialog]);

  //initialize page
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      if (ls.get<boolean>("user") === true) {
        setShowWelcome(true);
        ls.set<boolean>("user", false);
      }
      //retrieve list of components
      if (storedComponents && storedComponents.length > 0) {
        storedComponents.forEach((storedComponent) => {
          const [componentType, componentKey] = storedComponent.split("_");
          if (componentType === componentTypes.counter) {
            const storedCounterDetails = ls.get<ICounter>(
              `counter_${componentKey}`
            );
            if (storedCounterDetails) {
              addComponent(
                <CounterComponent
                  key={componentKey}
                  componentKey={+componentKey}
                  removeComponent={removeComponent}
                  storedCounterDetails={storedCounterDetails}
                />
              );
            }
          }

          if (componentType === componentTypes.dailies) {
            const storedDailiesDetails = ls.get<IDailies>(
              `dailies_${componentKey}`
            );
            if (storedDailiesDetails) {
              addComponent(
                <DailiesComponent
                  key={componentKey}
                  componentKey={+componentKey}
                  removeComponent={removeComponent}
                  storedDailiesDetails={storedDailiesDetails}
                />
              );
            }
          }

          if (componentType === componentTypes.timer) {
            const storedTimerDetails = ls.get<ITimer>(`timer_${componentKey}`);
            if (storedTimerDetails) {
              addComponent(
                <TimerComponent
                  key={componentKey}
                  componentKey={+componentKey}
                  removeComponent={removeComponent}
                  storedTimerDetails={storedTimerDetails!}
                />
              );
            }
          }

          if (componentType === componentTypes.notes) {
            const storedNotesDetails = ls.get<INotes>(`notes_${componentKey}`);
            if (storedNotesDetails) {
              addComponent(
                <NotesComponent
                  key={componentKey}
                  componentKey={+componentKey}
                  removeComponent={removeComponent}
                  storedNotesDetails={storedNotesDetails}
                />
              );
            }
          }

          if (componentType === componentTypes.todo) {
            const storedTodoDetails = ls.get<ITodo>(`todo_${componentKey}`);
            if (storedTodoDetails) {
              addComponent(
                <TodoComponent
                  key={componentKey}
                  componentKey={+componentKey}
                  removeComponent={removeComponent}
                  storedTodoDetails={storedTodoDetails}
                />
              );
            }
          }
          if (componentType === componentTypes.gamify) {
            const storedGamifyDetails = ls.get<IGamify>(
              `gamify_${componentKey}`
            );
            if (storedGamifyDetails) {
              addComponent(
                <GamifyComponent
                  key={componentKey}
                  componentKey={+componentKey}
                  removeComponent={removeComponent}
                  storedGamifyDetails={storedGamifyDetails}
                />
              );
            }
          }
          if (componentType === componentTypes.goal) {
            const storedGoalDetails = ls.get<IGoal>(`goal_${componentKey}`);
            if (storedGoalDetails) {
              addComponent(
                <GoalComponent
                  key={componentKey}
                  componentKey={+componentKey}
                  removeComponent={removeComponent}
                  storedGoalDetails={storedGoalDetails}
                />
              );
            }
          }
        });
      }
    }
  }, [storedComponents, addComponent, removeComponent]);

  return (
    <Track>
      <AppContext.Provider
        value={{ isDarkMode: isDarkMode, storedData: storedComponents }}
      >
        <Box style={styles.background}>
          <h3 style={styles.header}> Mindful Space </h3>

          <ContentContainer>
            <Tooltip title="Clear All" placement="bottom">
              <IconButton
                style={styles.clearAll}
                onClick={() => setShowDialog(true)}
              >
                <DeleteSweepIcon />
              </IconButton>
            </Tooltip>
          </ContentContainer>
          <ContentContainer styles={styles.darkModeToggle}>
            <DarkModeToggleButton
              onChange={turnLightsOff}
              isDarkMode={isDarkMode}
            />
          </ContentContainer>
          {showDialog && alertDialog()}
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              width: "100%",
              float: "left",
            }}
          >
            {componentsList}
          </Box>

          <AddComponentButton
            removeComponent={removeComponent}
            addComponent={addComponent}
          />
        </Box>
      </AppContext.Provider>
    </Track>
  );
}

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    background: {
      backgroundColor: isDarkMode ? "#121212" : "#f1f3f4",
      width: "100%",
      height: "100%",
      position: "absolute",
      transition: "background-color 0.5s ease",
      overflowX: "hidden",
    },
    darkModeToggle: {
      padding: 20,
      flexDirection: "row-reverse",
      display: "flex",
    },
    header: {
      float: "left",
      paddingLeft: 10,
      color: isDarkMode ? "white" : "#121212",
    },
    clearAll: {
      color: isDarkMode ? "white" : "#121212",
      padding: 20,
      flexDirection: "row-reverse",
      display: "flex",
      float: "left",
      position: "absolute",
      right: 84,
      top: 5,
    },
    addNewTab: {
      color: isDarkMode ? "white" : "#121212",
      padding: 20,
      flexDirection: "row-reverse",
      display: "flex",
      float: "left",
      position: "absolute",
      left: 140,
    },
    donateToUkraine: {
      width: 45,
      flexDirection: "row-reverse",
      display: "flex",
      float: "left",
      position: "absolute",
      top: 19,
      right: 150,
    },
  };
};

export default App;
