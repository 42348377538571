import { Box } from "@mui/material";
import * as ls from "local-storage";
import React, { useCallback, useEffect, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { componentTypes, IGoal } from "../../models";
import ContentContainer from "../ContentContainer";
import StepItem from "./StepItem";

interface IGoalComponentProps {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  storedGoalDetails?: IGoal;
}

const GoalComponent = ({
  removeComponent,
  componentKey,
  storedGoalDetails,
}: IGoalComponentProps) => {
  const [isDraggingDisabled, setIsDraggingDisabled] = useState(false);
  // const styles = stylesProvider(isDarkMode, height);
  const nodeRef = React.useRef(null);
  const [GoalSteps, setGoalSteps] = useState<JSX.Element[]>([]);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedGoalDetails?.positionX || 0,
    y: storedGoalDetails?.positionY || 0,
  });

  const disableDraggingHandler = useCallback(
    (enabled: boolean) => {
      setIsDraggingDisabled(enabled);
    },
    [setIsDraggingDisabled]
  );

  useEffect(() => {
    ls.set<IGoal>(`goal_${componentKey}`, {
      ...ls.get<IGoal>(`goal_${componentKey}`),
      goalName: storedGoalDetails?.goalName!,
      positionX: position?.x!,
      positionY: position?.y!,
    });
  }, [componentKey, position?.x, position?.y, storedGoalDetails?.goalName]);

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [position.x, position.y]
  );

  const AddGoalStep = useCallback(() => {
    const key = Math.random();
    setGoalSteps((prevGoalSteps) => [
      ...prevGoalSteps,
      <ContentContainer styles={{ position: "absolute", left: 350 }}>
        <StepItem
          removeComponent={removeComponent}
          componentKey={key}
          AddGoalStep={AddGoalStep}
          DisableDragging={disableDraggingHandler}
          key={key}
          displayStep
        />
      </ContentContainer>,
    ]);
  }, [disableDraggingHandler, removeComponent]);

  return (
    <Draggable
      disabled={isDraggingDisabled}
      nodeRef={nodeRef}
      onStop={onStop}
      defaultPosition={
        storedGoalDetails && position
          ? { x: position.x, y: position.y }
          : undefined
      }
    >
      <Box>
        <StepItem
          removeComponent={removeComponent}
          componentKey={componentKey}
          key={componentKey}
          AddGoalStep={AddGoalStep}
          leftMargin={0}
          DisableDragging={disableDraggingHandler}
          storedGoalDetails={storedGoalDetails}
        />
        {GoalSteps}
      </Box>
    </Draggable>
  );
};

// const stylesProvider = (isDarkMode: boolean, height: number): MyStyleSheet => {
//   return {
//     goal: {
//       height: "fit-content",
//       width: "fit-content",
//       position: "absolute",
//       top: "10%",
//       left: 10,
//     },
//     text: {
//       flexDirection: "row",
//       alignContent: "center",
//       textAlign: "center",
//       overflow: "hidden",
//       maxWidth: "290px",
//       wordBreak: "break-word",
//       marginTop: 0,
//     },
//     textField: {
//       width: "80%",
//       display: "flex",
//       alignSelf: "center",
//       justifySelf: "center",
//     },

//     optionsList: {
//       height: 100,
//       width: 294,
//       background: "white",
//       position: "absolute",
//       top: height + 40,
//       borderRadius: 5,
//       left: 10,
//     },
//   };
// };

export default GoalComponent;
