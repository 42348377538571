import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import * as ls from "local-storage";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AppContext } from "../../App";
import { componentTypes, ITodo, MyStyleSheet } from "../../models";
import ContentContainer from "../ContentContainer";
import OptionsComponent from "../Options/OptionsComponent";
import "./TodoComponent.css";
import TodoItem from "./TodoItem";

interface ITodoComponentProps {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  storedTodoDetails?: ITodo;
}

const TodoComponent = ({
  removeComponent,
  componentKey,
  storedTodoDetails,
}: ITodoComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const styles = stylesProvider(isDarkMode);
  const nodeRef = React.useRef(null);
  const initialLoad = useRef(true);
  const [todoText, setTodoText] = useState("");
  const [todoListItems, setTodoListItems] = useState<JSX.Element[]>([]);

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedTodoDetails?.positionX!,
    y: storedTodoDetails?.positionY!,
  });

  useEffect(() => {
    ls.set<ITodo>(`todo_${componentKey}`, {
      todoItems: ls.get<ITodo>(`todo_${componentKey}`)?.todoItems ?? [],
      positionX: position.x,
      positionY: position.y,
    });
  });

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }

      ls.set<ITodo>(`todo_${componentKey}`, {
        ...ls.get<ITodo>(`todo_${componentKey}`),
        positionX: data.x,
        positionY: data.y,
      });
    },
    [componentKey, position.x, position.y]
  );

  const updateTodo = useCallback(
    (key: number, checked?: boolean, updatedTodoText?: string) => {
      const storedTodo = ls.get<ITodo>(`todo_${componentKey}`);
      const foundItemIndex = storedTodo.todoItems.findIndex(
        (item) => item.todoKey === key
      );

      const storedCheck = storedTodo.todoItems[foundItemIndex].checked;
      const value = storedCheck === undefined ? false : !storedCheck;

      if (foundItemIndex !== -1) {
        storedTodo.todoItems[foundItemIndex] = {
          ...storedTodo.todoItems[foundItemIndex],
          checked: checked || value,
          todoText:
            updatedTodoText ?? storedTodo.todoItems[foundItemIndex].todoText,
        };
      }
      ls.set<ITodo>(`todo_${componentKey}`, {
        ...ls.get<ITodo>(`todo_${componentKey}`),
        todoItems: storedTodo.todoItems,
      });
    },
    [componentKey]
  );

  const removeTodo = useCallback(
    (key: number) => {
      setTodoListItems((prevItems) => [
        ...prevItems.filter((item) => item.key !== key.toString()),
      ]);

      ls.set<ITodo>(`todo_${componentKey}`, {
        ...ls.get<ITodo>(`todo_${componentKey}`),
        todoItems: ls
          .get<ITodo>(`todo_${componentKey}`)
          .todoItems.filter((item) => item.todoKey !== key),
      });
    },
    [componentKey]
  );

  useEffect(() => {
    if (initialLoad.current && storedTodoDetails?.todoItems?.length! > 0) {
      initialLoad.current = false;
      const todoItems = storedTodoDetails!.todoItems.map((item) => (
        <TodoItem
          updateTodo={updateTodo}
          storedCheck={item.checked}
          originalTodoText={item.todoText}
          removeTodo={removeTodo}
          key={item.todoKey}
          todoKey={item.todoKey}
        />
      ));

      setTodoListItems(todoItems);
    }
  }, [updateTodo, removeTodo, storedTodoDetails]);

  const addTodo = () => {
    const key = Math.random();
    setTodoListItems((prevItems) => [
      ...prevItems,
      <TodoItem
        updateTodo={updateTodo}
        originalTodoText={todoText}
        removeTodo={removeTodo}
        key={key}
        todoKey={key}
      />,
    ]);

    const storedTodoItems = ls.get<ITodo>(`todo_${componentKey}`).todoItems;

    ls.set<ITodo>(`todo_${componentKey}`, {
      ...ls.get<ITodo>(`todo_${componentKey}`),
      todoItems: [
        ...(storedTodoItems?.length > 0 ? storedTodoItems : []),
        {
          todoText: todoText,
          todoKey: key,
          checked: false,
        },
      ],
    });
    setTodoText("");
  };

  return (
    <Draggable
      nodeRef={nodeRef}
      onStop={onStop}
      defaultPosition={
        storedTodoDetails?.positionX && position
          ? { x: position.x, y: position.y }
          : undefined
      }
    >
      <div ref={nodeRef}>
        <Box
          sx={{
            bgcolor: "background.paper",
            border: 2,
            borderRadius: 1,
            borderColor: isDarkMode ? "white" : "grey",
            position: "relative",
            top: 100,
            right: -25,
          }}
          style={styles.todoList}
        >
          <ContentContainer
            styles={{
              width: 320,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <OptionsComponent
              key={Math.random()}
              dark={isDarkMode}
              light={!isDarkMode}
              componentKey={componentKey}
              removeComponent={removeComponent}
              componentType={componentTypes.todo}
            />
          </ContentContainer>
          <ContentContainer styles={{ minHeight: 350 }}>
            {todoListItems}
          </ContentContainer>

          <ContentContainer
            styles={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <TextField
              fullWidth
              variant="standard"
              className={isDarkMode ? "TodoDarkMode" : "Todo"}
              style={{
                width: "100 %",
                flexDirection: "row",
                justifyContent: "flex-start",
                left: 1,
                border: "solid",
                borderWidth: 0.5,
                borderRadius: 2,
                borderColor: "#246ec2",
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addTodo();
                }
              }}
              value={todoText}
              onChange={(e) => setTodoText(e.target.value)}
            />
          </ContentContainer>
        </Box>
      </div>
    </Draggable>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    todoList: {
      border: "none",
      background: isDarkMode ? "white" : "#25252d",
      width: "fit-content",
      position: "absolute",
      top: "10%",
      left: 10,
    },
  };
};

export default TodoComponent;
