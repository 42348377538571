import Box from "@material-ui/core/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import * as ls from "local-storage";
import { DateTime, Duration } from "luxon";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AppContext } from "../../App";
import { componentTypes, ITimer, MyStyleSheet, timerState } from "../../models";
import ContentContainer from "../ContentContainer";
import OptionsComponent from "../Options/OptionsComponent";
import "./TimerComponent.css";

interface ITimerComponentProps {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  storedTimerDetails?: ITimer;
}

const TimerComponent = ({
  removeComponent,
  componentKey,
  storedTimerDetails,
}: ITimerComponentProps) => {
  const nodeRef = React.useRef(null);
  const { isDarkMode } = useContext(AppContext);
  const duration = useRef<Duration>(
    Duration.fromISOTime(storedTimerDetails?.timeLeft!) || undefined
  );
  const timeStart = useRef<DateTime>(DateTime.local());
  const currentIntervalId = useRef<NodeJS.Timeout>();

  const [time, setTime] = useState(storedTimerDetails?.time || "00:00");
  const [customTime, setCustomTime] = useState(60);
  const [customTimer, setCustomTimer] = useState(false);
  const [timerState, setTimerState] = useState<timerState>(
    storedTimerDetails?.timerState!
  );
  const audio = new Audio(
    "https://cdn.pixabay.com/download/audio/2021/08/04/audio_bb630cc098.mp3?filename=short-success-sound-glockenspiel-treasure-video-game-6346.mp3"
  );
  const styles = stylesProvider(isDarkMode, customTimer);

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedTimerDetails?.positionX!,
    y: storedTimerDetails?.positionY!,
  });

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [position.x, position.y]
  );

  useEffect(() => {
    ls.set<ITimer>(`timer_${componentKey}`, {
      timeLeft: duration?.current.toISOTime(),
      time: time,
      timerState: timerState!,
      positionX: position?.x!,
      positionY: position?.y!,
    });
  }, [componentKey, position?.x, position?.y, time, timerState]);

  useEffect(() => {
    if (storedTimerDetails?.timeLeft) {
      timeStart.current = DateTime.local().plus(duration.current);
    }
  }, [storedTimerDetails?.timeLeft]);

  const computeTime = useCallback((minutes: number, seconds: number) => {
    const minutesCounter = `${minutes}`;
    const computedMinute =
      minutesCounter.length === 1 ? `0${minutesCounter}` : minutesCounter;

    const secondsCounter = `${seconds}`;
    const computedSecond =
      secondsCounter.length === 1 ? `0${secondsCounter}` : secondsCounter;

    return `${computedMinute}:${computedSecond}`;
  }, []);

  const startTimer = useCallback(
    (minutes: number, seconds: number) => () => {
      if (timerState === "paused") {
        timeStart.current = DateTime.local().plus(duration.current!);
        setTimerState("started");
      } else {
        timeStart.current = DateTime.local().plus({
          minutes,
          seconds: seconds + 1,
        });
        setTime(computeTime(minutes, seconds));
        setCustomTimer(false);
        setTimerState("started");
      }
    },
    [computeTime, timerState]
  );

  const stopTimer = useCallback(() => {
    setCustomTimer(false);
    setTimerState("stopped");
  }, []);

  const pauseTimer = useCallback(() => {
    setTimerState("paused");
  }, []);

  useEffect(() => {
    if (timerState === "paused") {
      clearInterval(currentIntervalId.current!);
    }
    if (timerState === "started") {
      currentIntervalId.current = setInterval(() => {
        const diff = timeStart.current.diffNow();

        duration.current = diff;
        if (diff.toFormat("mm:ss") === "00:00" || diff.valueOf() < 0) {
          audio.play();
          stopTimer();
          alert(`Time's up`);
        }
        setTime(diff.toFormat("mm:ss"));
      }, 1000);
    }

    return () => {
      clearInterval(currentIntervalId.current!);
    };
  }, [audio, stopTimer, timerState]);

  return (
    <Draggable
      nodeRef={nodeRef}
      onStop={onStop}
      defaultPosition={
        storedTimerDetails && position
          ? { x: position.x, y: position.y }
          : undefined
      }
    >
      <div ref={nodeRef}>
        <Box style={styles.counter}>
          <ContentContainer
            styles={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: -10,
            }}
          >
            <OptionsComponent
              key={Math.random()}
              componentKey={componentKey}
              removeComponent={removeComponent}
              componentType={componentTypes.timer}
            />
          </ContentContainer>
          {customTimer ? (
            <Box style={styles.timer}>
              <div
                style={{
                  position: "relative",
                  top: 20,
                  fontSize: 60,
                  fontWeight: "bold",
                }}
              >
                <ContentContainer styles={{ flexDirection: "row" }}>
                  <TextField
                    style={{
                      fontSize: 40,
                      top: 20,
                      position: "relative",
                    }}
                    className={isDarkMode ? "TimerDarkMode" : "Timer"}
                    onChange={(e) =>
                      setCustomTime(
                        parseInt(
                          e.target.value.length > 0 ? e.target.value : "0"
                        )
                      )
                    }
                    onKeyDown={(e) =>
                      e.key === "Enter" ? startTimer(customTime, 0)() : null
                    }
                    value={customTime}
                  />
                  <IconButton
                    style={{
                      color: isDarkMode ? "white" : "#121212",
                      float: "right",
                      bottom: 65,
                    }}
                    onClick={() => setCustomTime((prev) => prev + 25)}
                    onTouchStart={() => setCustomTime((prev) => prev + 25)}
                  >
                    <ArrowDropUpIcon />
                  </IconButton>
                  <IconButton
                    style={{
                      color: isDarkMode ? "white" : "#121212",
                      float: "right",
                      bottom: 35,
                      left: 40,
                    }}
                    onClick={() =>
                      setCustomTime((prev) => (prev - 25 < 0 ? 0 : prev - 25))
                    }
                    onTouchStart={() =>
                      setCustomTime((prev) => (prev - 25 < 0 ? 0 : prev - 25))
                    }
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </ContentContainer>
                <span
                  style={{
                    fontSize: 20,
                    position: "relative",
                    bottom: 45,
                    left: "38%",
                  }}
                >
                  mins
                </span>
              </div>
            </Box>
          ) : (
            <></>
          )}
          {timerState === "started" || timerState === "paused" ? (
            <Box style={styles.timer}>
              <div
                style={{
                  position: "relative",
                  top: 20,
                  fontSize: 60,
                  fontWeight: "bold",
                }}
              >
                {time}
              </div>
            </Box>
          ) : customTimer ? (
            <> </>
          ) : (
            <ContentContainer>
              <ContentContainer>
                <a
                  href="/#"
                  style={styles.circleButton}
                  onClick={startTimer(5, 0)}
                  onTouchStart={startTimer(5, 0)}
                >
                  5
                </a>
                <a
                  href="/#"
                  style={styles.circleButton}
                  onClick={startTimer(10, 0)}
                  onTouchStart={startTimer(10, 0)}
                >
                  10
                </a>
                <a
                  href="/#"
                  style={styles.circleButton}
                  onClick={startTimer(20, 0)}
                  onTouchStart={startTimer(20, 0)}
                >
                  20
                </a>
                <a
                  href="/#"
                  style={styles.circleButton}
                  onClick={startTimer(30, 0)}
                  onTouchStart={startTimer(30, 0)}
                >
                  30
                </a>
              </ContentContainer>
              <ContentContainer styles={{ top: 10, position: "relative" }}>
                <a
                  href="/#"
                  style={styles.circleButton}
                  onClick={startTimer(45, 0)}
                  onTouchStart={startTimer(45, 0)}
                >
                  45
                </a>
                <a
                  href="/#"
                  style={styles.circleButton}
                  onClick={startTimer(60, 0)}
                  onTouchStart={startTimer(60, 0)}
                >
                  60
                </a>
                <a
                  href="/#"
                  style={styles.customButton}
                  onTouchStart={() => setCustomTimer(true)}
                  onClick={() => setCustomTimer(true)}
                >
                  Custom
                </a>
              </ContentContainer>
            </ContentContainer>
          )}

          <ContentContainer styles={{ width: "100%" }}>
            {/**default timer is 30 minutes**/}
            <Button
              onClick={
                timerState === "started"
                  ? pauseTimer
                  : customTimer
                  ? startTimer(customTime, 0)
                  : startTimer(30, 0)
              }
              onTouchStart={
                timerState === "started"
                  ? pauseTimer
                  : customTimer
                  ? startTimer(customTime, 0)
                  : startTimer(30, 0)
              }
              style={styles.startButton}
            >
              {timerState === "started" ? "Pause" : "Start"}
            </Button>
            <Button
              onClick={stopTimer}
              onTouchStart={stopTimer}
              style={styles.resetButton}
            >
              Reset
            </Button>
          </ContentContainer>
        </Box>
      </div>
    </Draggable>
  );
};

const stylesProvider = (
  isDarkMode: boolean,
  isCustomTimer: boolean
): MyStyleSheet => {
  return {
    counter: {
      border: 4,
      width: "200px",
      height: "200px",
      borderRadius: "25px",
      color: isDarkMode ? "white" : "#121212",
      backgroundColor: "#0288d1",
      position: "absolute",
      top: "10%",
      left: 10,
    },
    circleButton: {
      display: "block",
      height: 30,
      width: 30,
      borderWidth: 2,
      border: "2px solid",
      borderColor: "#0288d1",
      borderRadius: "50%",
      textDecoration: "none",
      boxShadow: "0 0 3px #0288d1",
      fontSize: 20,
      backgroundColor: "#0288d1",
      color: isDarkMode ? "white" : "#25252d",

      textAlign: "center",
      cursor: "pointer",
      position: "relative",
      top: 30,
      left: 15,
      marginRight: 10,
      float: "left",
    },
    customButton: {
      display: "block",
      height: 30,
      width: 75,
      borderWidth: 2,
      border: "2px solid #0288d1",
      borderRadius: "8%",
      textDecoration: "none",
      boxShadow: "0 0 3px #0288d1",
      backgroundColor: "#0288d1",
      fontSize: 20,
      color: isDarkMode ? "white" : "#25252d",
      textAlign: "center",
      cursor: "pointer",
      position: "relative",
      top: 30,
      left: 15,
      marginRight: 10,
      float: "left",
    },
    startButton: {
      position: "relative",
      top: isCustomTimer ? -10 : 70,
      left: 10,
      float: "left",
      color: isDarkMode ? "white" : "#25252d",
    },
    resetButton: {
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      top: isCustomTimer ? -10 : 70,
      left: 55,
      color: isDarkMode ? "white" : "#25252d",
    },
    timer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
  };
};

export default TimerComponent;
