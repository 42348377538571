import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Button,
  ClickAwayListener,
  IconButton,
  TextField,
} from "@mui/material";
import { DateTime, Info } from "luxon";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import { ICalendar, MyStyleSheet } from "../../models";
import ContentContainer from "../ContentContainer";
import "./CalendarComponent.css";

enum calendarNavigation {
  forward = 1,
  backward = -1,
}

interface ICalendarComponentProps {
  onHandleTodayDone?: (habitId: string) => void;
  habitId: string;
  isTodayDone: boolean;
  onDone: (streakDays: string[]) => void;
  storedCalendarDetails?: ICalendar;
}

const CalendarComponent = ({
  onHandleTodayDone,
  habitId,
  isTodayDone,
  onDone,
  storedCalendarDetails,
}: ICalendarComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const now: DateTime = DateTime.now();
  const info = Info;
  const daysOfWeek = info.weekdays("short");
  const selectedDate = useRef<DateTime>(now);
  const [currentDateTime, setCurrentDateTime] = useState<DateTime>(now);
  const [diaryContent, setDiaryContent] = useState("");
  const [displayDiary, setDisplayDiary] = useState(false);
  const [displayDiaryText, setDisplayDiaryText] = useState(false);
  const [streakDays, setStreakDays] = useState<string[]>(
    storedCalendarDetails?.streakDays || []
  );

  const isOnce = useRef(
    !!storedCalendarDetails?.streakDays.find(
      (x) =>
        x ===
        DateTime.local(
          selectedDate.current.year,
          selectedDate.current.month,
          selectedDate.current.day
        ).toISO()
    )
  );

  const styles = stylesProvider(isDarkMode);

  const handleOnClick = useCallback((day: number | string) => {
    if (day !== "") {
      selectedDate.current = selectedDate.current.set({ day: day as number });
      setCurrentDateTime(selectedDate.current);
    }
  }, []);

  const handleIsDone = useCallback(() => {
    const date = DateTime.local(
      selectedDate.current.year,
      selectedDate.current.month,
      selectedDate.current.day
    ).toISO();

    if (selectedDate.current.day === DateTime.local().day) {
      isOnce.current = !isOnce.current;
      onHandleTodayDone!(habitId);
    }

    if (!streakDays.includes(date)) {
      setStreakDays((prev) => [...prev, date]);
    } else {
      setStreakDays((prev) => prev.filter((x) => x !== date));
    }
  }, [habitId, onHandleTodayDone, streakDays]);

  useEffect(() => {
    if (streakDays !== storedCalendarDetails?.streakDays) {
      onDone(streakDays);
    }
  }, [onDone, storedCalendarDetails, streakDays]);

  useEffect(() => {
    if (isTodayDone !== isOnce.current) {
      isOnce.current = isTodayDone;
      const date = DateTime.now().day;
      handleOnClick(date);

      const dateISO = DateTime.local(
        selectedDate.current.year,
        selectedDate.current.month,
        selectedDate.current.day
      ).toISO();

      if (!streakDays.includes(dateISO)) {
        setStreakDays((prev) => [...prev, dateISO]);
      } else {
        setStreakDays((prev) => prev.filter((x) => x !== dateISO));
      }
    }
  }, [handleOnClick, isTodayDone, streakDays]);

  const styleCricle = useCallback(
    (day: number | string) => {
      if (day !== "") {
        const isDateSelected = selectedDate.current.day === day;
        const isDateStreakDay = streakDays.includes(
          DateTime.local(
            currentDateTime.year,
            currentDateTime.month,
            day as number
          ).toISO()
        );

        if (isDateSelected) {
          return isDateStreakDay
            ? styles.doneCircleButton
            : styles.selectedCircleButton;
        } else {
          return isDateStreakDay
            ? styles.doneCircleButton
            : styles.circleButton;
        }
      }
      return styles.circleButton;
    },
    [
      currentDateTime.month,
      currentDateTime.year,
      streakDays,
      styles.circleButton,
      styles.doneCircleButton,
      styles.selectedCircleButton,
    ]
  );

  const createCalendar = useCallback(
    (month: DateTime) => {
      const startOfTheMonth = month.startOf("month").weekday;
      const daysInMonth = month.daysInMonth;
      let daysOfMonth = [];

      for (let x = 1; x < startOfTheMonth; x++) {
        daysOfMonth.push("");
      }
      daysOfMonth = [
        ...daysOfMonth,
        ...Array.from(Array(daysInMonth).keys()).map((x) => ++x),
      ];

      let weeks = [];
      while (daysOfMonth.length) {
        weeks.push(daysOfMonth.splice(0, 7));
      }
      const calendar = weeks.map((days, index) => {
        return (
          <div
            style={{
              display: "flex",
            }}
            key={index}
          >
            {days.map((day, index) => (
              <div
                onClick={() => handleOnClick(day as number)}
                onTouchStart={() => handleOnClick(day as number)}
                onDoubleClick={() => setDisplayDiary(true)}
                style={styleCricle(day)}
                key={index + 100}
              >
                {day}
              </div>
            ))}
          </div>
        );
      });
      return calendar;
    },
    [handleOnClick, styleCricle]
  );

  const displayDiaryEntry = useCallback(
    (Date: DateTime) => {
      return (
        <ContentContainer styles={{ height: 340 }}>
          <IconButton
            style={{
              marginBottom: -20,
              marginTop: -20,
              color: isDarkMode ? "white" : "#121212",
            }}
            onClick={() => setDisplayDiary(false)}
            onTouchStart={() => setDisplayDiary(false)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ textAlign: "center" }}>
            {`${Date.weekdayLong}, ${Date.day} ${Date.monthShort}`}{" "}
          </h2>
          {displayDiaryText ? (
            <h5
              onClick={() => setDisplayDiaryText(false)}
              onTouchStart={() => setDisplayDiaryText(false)}
              style={styles.text}
            >
              {diaryContent}
            </h5>
          ) : (
            <ClickAwayListener
              onClickAway={() => setDisplayDiaryText(diaryContent.length > 0)}
            >
              <TextField
                className="Diary"
                multiline={true}
                value={diaryContent}
                onChange={(e) => setDiaryContent(e.target.value)}
              />
            </ClickAwayListener>
          )}
        </ContentContainer>
      );
    },
    [diaryContent, displayDiaryText, isDarkMode, styles.text]
  );
  //on click <- or -> will take you one month in the past or the future
  const navigateMonth = (forwardOrBackward: calendarNavigation) => {
    const targetDateTime = currentDateTime.plus({
      month: forwardOrBackward === calendarNavigation.forward ? 1 : -1,
    });
    selectedDate.current = targetDateTime;
    setCurrentDateTime(targetDateTime);
  };

  const navigateYear = (forwardOrBackward: calendarNavigation) => {
    setCurrentDateTime((prevDateTime) =>
      prevDateTime.plus({
        year: forwardOrBackward === calendarNavigation.forward ? 1 : -1,
      })
    );
  };

  return displayDiary ? (
    displayDiaryEntry(selectedDate.current)
  ) : (
    <ContentContainer styles={styles.calendar}>
      <ContentContainer styles={{ top: 20, position: "relative" }}>
        <IconButton
          style={{
            color: isDarkMode ? "white" : "#121212",
            float: "left",
            bottom: 9,
            left: 65,
            marginRight: -35,
          }}
          onClick={() => navigateMonth(calendarNavigation.backward)}
          onTouchStart={() => navigateMonth(calendarNavigation.backward)}
        >
          <ArrowLeftIcon />
        </IconButton>
        <h4 style={{ textAlign: "center" }}> {currentDateTime.monthLong} </h4>
        <IconButton
          style={{
            color: isDarkMode ? "white" : "#121212",
            float: "right",
            bottom: 50,
            left: -60,
            marginLeft: -35,
          }}
          onClick={() => navigateMonth(calendarNavigation.forward)}
          onTouchStart={() => navigateMonth(calendarNavigation.forward)}
        >
          <ArrowRightIcon />
        </IconButton>
        <IconButton
          style={{
            color: isDarkMode ? "white" : "#121212",
            float: "left",
            bottom: 26,
            left: 85,
            marginRight: -35,
          }}
          onClick={() => navigateYear(calendarNavigation.backward)}
          onTouchStart={() => navigateYear(calendarNavigation.backward)}
        >
          <ArrowLeftIcon />
        </IconButton>
        <h4 style={{ marginTop: -18 }}> {currentDateTime.year} </h4>
        <IconButton
          style={{
            color: isDarkMode ? "white" : "#121212",
            float: "right",
            bottom: 51,
            left: -83,
            marginBottom: -24,
          }}
          onClick={() => navigateYear(calendarNavigation.forward)}
          onTouchStart={() => navigateYear(calendarNavigation.forward)}
        >
          <ArrowRightIcon />
        </IconButton>
      </ContentContainer>
      <ContentContainer styles={{ width: "100%" }}>
        <div
          style={{
            width: "106%",
            fontSize: 16,
            marginBottom: 18,
            justifyContent: "space-evenly",
            position: "relative",
            display: "flex",
          }}
        >
          {daysOfWeek.map((x, index) => (
            <div key={index} style={{ marginLeft: -24, fontWeight: 500 }}>
              {x}
            </div>
          ))}
        </div>
        {createCalendar(currentDateTime)}
      </ContentContainer>
      <ContentContainer
        styles={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          onClick={handleIsDone}
          onTouchStart={handleIsDone}
          variant={"contained"}
        >
          Done
        </Button>
      </ContentContainer>
    </ContentContainer>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    calendar: {
      position: "relative",
      display: "felx",
      flexDirection: "row",
      width: "100%",
      textAlign: "center",
    },
    header: {
      marginTop: -40,
    },
    circleButton: {
      borderWidth: 2,
      position: "relative",
      border: "1.5px solid",
      borderColor: undefined,
      borderRadius: "50%",
      //color: "#0288d9",
      textAlign: "center",
      cursor: "pointer",
      fontWeight: 500,
      fontSize: 20,
      height: 30,
      width: 30,
      display: "felx",
      marginLeft: 5,
      marginBottom: 5,
    },
    selectedCircleButton: {
      borderWidth: 2,
      position: "relative",
      border: "1.5px solid",
      borderColor: "#0288d9",
      borderRadius: "50%",
      //color: "#0288d9",
      textAlign: "center",
      cursor: "pointer",
      fontWeight: 500,
      fontSize: 20,
      height: 30,
      width: 30,
      display: "felx",
      marginLeft: 5,
      marginBottom: 5,
    },
    doneCircleButton: {
      borderWidth: 2,
      position: "relative",
      border: "1.5px solid",
      borderColor: "#0288d9",
      borderRadius: "50%",
      backgroundColor: "#0288d9",
      textAlign: "center",
      cursor: "pointer",
      fontWeight: 500,
      fontSize: 20,
      height: 30,
      width: 30,
      display: "felx",
      marginLeft: 5,
      marginBottom: 5,
    },
    text: {
      overflow: "hidden",
      maxWidth: "250px",
      wordBreak: "break-word",
      fontWeight: "normal",
      marginLeft: 10,
    },
  };
};

export default CalendarComponent;
