import { Box, IconButton } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AppContext } from "../../../App";
import {
  ColorMap,
  componentTypes,
  HabitIconTypes,
  IDailies,
  IHabit,
  ITracker,
} from "../../../models";
import ContentContainer from "../../ContentContainer";

import AddIcon from "@mui/icons-material/Add";

import * as ls from "local-storage";
import OptionsComponent from "../../Options/OptionsComponent";

import HabitTrackerComponent from "../HabitTrackerComponent";
import { DailiesComponentStylesProvider } from "./DaliesComponent.Styles";
import HabitComponent from "./HabitComponent";

interface IDailiesComponentProps {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  storedDailiesDetails?: IDailies;
}

export const DailiesComponent = ({
  removeComponent,
  componentKey,
  storedDailiesDetails,
}: IDailiesComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const styles = DailiesComponentStylesProvider(isDarkMode);
  const nodeRef = React.useRef(null);

  /** State Variables */

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedDailiesDetails?.positionX!,
    y: storedDailiesDetails?.positionY!,
  });

  const [habits, setHabits] = useState<Array<IHabit>>(
    storedDailiesDetails?.habits || [
      {
        name: "",
        done: false,
        index: 0,
        id: `${Math.random()}`,
        iconType: HabitIconTypes.workout,
        iconColor: ColorMap[1],
      },
    ]
  );

  const [displayedHabitTrackers, setDisplayedHabitTrackers] = useState<
    Array<boolean>
  >(storedDailiesDetails?.habitTrackersDisplayed || [false]);

  const onHandleDone = useCallback(
    (habitId: string) => {
      const updatedHabits = habits;
      const foundHabitIndex = habits.findIndex((habit) => habit.id === habitId);
      updatedHabits[foundHabitIndex]!.done =
        !updatedHabits[foundHabitIndex]?.done;
      setHabits([...updatedHabits]);
    },
    [habits]
  );

  const onHandleUpdate = useCallback(
    (
      habitId: string,
      habitName?: string,
      iconType?: HabitIconTypes,
      iconColor?: string
    ) => {
      const updatedHabits = habits;
      const foundHabitIndex = habits.findIndex((habit) => habit.id === habitId);
      if (iconType) updatedHabits[foundHabitIndex].iconType = iconType;
      if (habitName) updatedHabits[foundHabitIndex].name = habitName;
      if (iconColor && iconColor !== habits[foundHabitIndex].iconColor) {
        updatedHabits[foundHabitIndex].iconColor = iconColor;
        onHandleDone(habitId);
        onHandleDone(habitId);
      }

      ls.set<IDailies>(`dailies_${componentKey}`, {
        ...ls.get<IDailies>(`dailies_${componentKey}`),
        habits: updatedHabits,
      });

      if (updatedHabits !== habits) setHabits([...updatedHabits]);
    },
    [componentKey, habits, onHandleDone]
  );

  /***/

  /** UseEffects */

  useEffect(() => {
    ls.set<IDailies>(`dailies_${componentKey}`, {
      positionX: position.x,
      positionY: position.y,
      habits: habits,
      habitTrackersDisplayed: displayedHabitTrackers,
      habitTrackersStoredDetails: habits.map((x) =>
        ls.get<ITracker>(`tracker_${x.id}`)
      ),
    });
  }, [componentKey, displayedHabitTrackers, habits, position.x, position.y]);

  /***/

  /** Callbacks */

  const addHabit = useCallback(() => {
    const habitIndex = habits.length - 1;
    setHabits((habits) => [
      ...habits,
      {
        name: "",
        done: false,
        index: habitIndex,
        id: `${habitIndex + Math.random()}`,
        iconType: HabitIconTypes.focus,
        iconColor: ColorMap[habitIndex + 1],
      },
    ]);

    setDisplayedHabitTrackers((displayedTrackers) => [
      ...displayedTrackers,
      false,
    ]);
  }, [habits.length]);

  const removeHabit = useCallback(
    (id: string) => {
      if (habits.length > 1) {
        setHabits((habits) => habits.filter((h) => h.id !== id));
      }
    },
    [habits.length]
  );

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [position.x, position.y]
  );

  const displayHideHabitTracker = useCallback(
    (habitId: string) => {
      const updatedHabitTrackers = displayedHabitTrackers;
      const foundHabitIndex = habits.findIndex((habit) => habit.id === habitId);
      updatedHabitTrackers[foundHabitIndex] =
        !updatedHabitTrackers[foundHabitIndex];
      setDisplayedHabitTrackers([...updatedHabitTrackers]);
    },
    [displayedHabitTrackers, habits]
  );

  /***/

  /** useMemo */

  const habitsList = useMemo(() => {
    return habits.map((habit, index) => (
      <HabitComponent
        key={index + Math.random()}
        habit={habit}
        removeHabit={removeHabit}
        onHabitDone={onHandleDone}
        setDisplayHabitTracker={displayHideHabitTracker}
        isHabitTrackerDisplayed={displayedHabitTrackers[index]}
        updateHabit={onHandleUpdate}
      />
    ));
  }, [
    displayHideHabitTracker,
    displayedHabitTrackers,
    habits,
    onHandleDone,
    onHandleUpdate,
    removeHabit,
  ]);

  const habitTrackersList = useMemo(() => {
    return habits.map(
      (habit, index) =>
        displayedHabitTrackers[index] && (
          <HabitTrackerComponent
            habit={habit}
            key={habit.id}
            componentKey={+habit.id}
            isTodayDone={habit.done}
            removeComponent={() => displayHideHabitTracker(habit.id)}
            onHandleTodayDone={onHandleDone}
            storedHabitTrackerDetails={ls.get<ITracker>(`tracker_${habit.id}`)}
          />
        )
    );
  }, [displayHideHabitTracker, displayedHabitTrackers, habits, onHandleDone]);

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        onStop={onStop}
        defaultPosition={
          storedDailiesDetails?.positionX && position
            ? { x: position.x, y: position.y }
            : undefined
        }
      >
        <Box style={styles.counter}>
          <ContentContainer
            styles={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <OptionsComponent
              key={Math.random()}
              componentKey={componentKey}
              removeComponent={removeComponent}
              componentType={componentTypes.dailies}
            />
          </ContentContainer>
          <IconButton style={styles.addIcon} onClick={addHabit}>
            <AddIcon
              sx={{
                color: isDarkMode ? "white" : "black",
                background: isDarkMode ? "black" : "white",
                borderRadius: 25,
              }}
            />
          </IconButton>
          <ContentContainer
            styles={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {habitsList}
          </ContentContainer>
        </Box>
      </Draggable>
      {habitTrackersList}
    </>
  );
};
