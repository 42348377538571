import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import * as ls from "local-storage";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AppContext } from "../../App";
import {
  ColorMap,
  componentTypes,
  HabitIconTypes,
  ICalendar,
  IHabit,
  ITracker,
  MyStyleSheet,
} from "../../models";
import CalendarComponent from "../Calendar/CalendarComponent";
import ContentContainer from "../ContentContainer";
import OptionsComponent from "../Options/OptionsComponent";
import { RenderHabitIcon } from "./Habit.utils";

interface IHabitComponentProps {
  habit: IHabit;
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  onHandleTodayDone?: (habitId: string) => void;
  onHandleUpdate?: (componentKey: number) => void;
  isTodayDone?: boolean;
  storedHabitTrackerDetails?: ITracker;
}

const HabitTrackerComponent = ({
  habit,
  removeComponent,
  componentKey,
  onHandleTodayDone,
  onHandleUpdate,
  isTodayDone = false,
  storedHabitTrackerDetails,
}: IHabitComponentProps) => {
  const nodeRef = React.useRef(null);
  const { isDarkMode } = useContext(AppContext);

  const { iconType: habitIconType, id: habitId, iconColor } = habit;

  const storedCalendarDetails = ls.get<ICalendar>(`calendar_${componentKey}`);
  const styles = stylesProvider(isDarkMode);

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedHabitTrackerDetails?.positionX!,
    y: storedHabitTrackerDetails?.positionY!,
  });

  useEffect(() => {
    ls.set<ITracker>(`tracker_${componentKey}`, {
      positionX: position.x,
      positionY: position.y,
    });
  });

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }

      ls.set<ITracker>(`tracker_${componentKey}`, {
        ...ls.get<ITracker>(`tracker_${componentKey}`),
        positionX: data.x,
        positionY: data.y,
      });
      if (!!onHandleUpdate) {
        onHandleUpdate(componentKey);
      }
    },
    [componentKey, onHandleUpdate, position.x, position.y]
  );

  const onDone = useCallback(
    (streakDays: string[]) => {
      ls.set<ICalendar>(`calendar_${componentKey}`, {
        streakDays: streakDays,
      });
    },
    [componentKey]
  );

  return (
    <Draggable
      nodeRef={nodeRef}
      onStop={onStop}
      defaultPosition={
        storedHabitTrackerDetails?.positionX && position
          ? { x: position.x, y: position.y }
          : undefined
      }
    >
      <Box style={styles.Diary}>
        <ContentContainer
          styles={{
            width: "98%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: -10,
          }}
        >
          <OptionsComponent
            key={Math.random()}
            componentKey={componentKey}
            removeComponent={removeComponent}
            componentType={componentTypes.habit}
          />
        </ContentContainer>
        <ContentContainer
          styles={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: -30,
          }}
        >
          <Avatar
            sx={{
              bgcolor:
                habitIconType === HabitIconTypes.meditation
                  ? ColorMap[4]
                  : iconColor,
              width: 60,
              height: 60,
            }}
          >
            {RenderHabitIcon(habitIconType ?? HabitIconTypes.workout)}
          </Avatar>
        </ContentContainer>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "relative",
            top: 40,
            fontSize: 20,
            paddingBottom: 20,
          }}
        >
          {habit.name}
        </span>
        <CalendarComponent
          onHandleTodayDone={onHandleTodayDone}
          habitId={habitId}
          isTodayDone={isTodayDone}
          storedCalendarDetails={storedCalendarDetails}
          onDone={onDone}
        />
      </Box>
    </Draggable>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    Diary: {
      border: 4,
      borderStyle: "solid",
      width: 275,
      height: "fit-content",
      borderRadius: "25px",
      color: isDarkMode ? "white" : "#121212",
      position: "absolute",
      top: "10%",
      left: 10,
    },
  };
};

export default HabitTrackerComponent;
