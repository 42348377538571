import { MyStyleSheet } from "../../../models";

export const DailiesComponentStylesProvider = (
  isDarkMode: boolean
): MyStyleSheet => {
  return {
    counter: {
      border: 3,
      borderStyle: "solid",
      width: "fit-content",
      justifyContent: "space-evenly",
      height: 280,
      borderRadius: "25px",
      color: isDarkMode ? "white" : "#121212",
      position: "absolute",
      top: "10%",
      left: 10,
    },
    addIcon: {
      float: "right",
      display: "flex",
      position: "relative",
      top: "40%",
      left: 1,
      width: 0,
      padding: 0,
      margin: 0,
    },
  };
};
