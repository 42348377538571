import DarkModeToggle from "react-dark-mode-toggle";

export interface IDarkModeToggleProps {
  onChange: () => void;
  isDarkMode: boolean;
}

const DarkModeToggleButton = ({
  onChange,
  isDarkMode,
}: IDarkModeToggleProps) => {
  return <DarkModeToggle onChange={onChange} checked={isDarkMode} size={70} />;
};

export default DarkModeToggleButton;
