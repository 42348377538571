import React, { CSSProperties } from "react";

interface IContainerProps {
  children: any;
  styles?: CSSProperties | undefined;
}

const ContentContainer = ({ children, styles }: IContainerProps) => {
  return <div style={styles}>{children}</div>;
};

export default ContentContainer;
