import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { HabitIconTypes } from "../../models";

export const RenderHabitIcon = (habitIconType: HabitIconTypes) => {
  if (habitIconType === HabitIconTypes.focus) {
    return <BoltOutlinedIcon sx={{ width: 35, height: 35 }} />;
  }
  if (habitIconType === HabitIconTypes.meditation) {
    return <SelfImprovementIcon sx={{ width: 35, height: 35 }} />;
  }
  if (habitIconType === HabitIconTypes.read) {
    return <AutoStoriesIcon sx={{ width: 35, height: 35 }} />;
  }
  if (habitIconType === HabitIconTypes.workout) {
    return <FitnessCenterIcon sx={{ width: 35, height: 35 }} />;
  }
};
