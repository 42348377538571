import Add from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";
import * as ls from "local-storage";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AppContext } from "../../App";
import {
  componentTypes,
  IGamify,
  IMission,
  IReward,
  MyStyleSheet,
} from "../../models";
import ContentContainer from "../ContentContainer";
import OptionsComponent from "../Options/OptionsComponent";
import MissionComponent from "./MissionComponent";
import RewardsComponent from "./RewardsComponent";

interface IGamificationComponentProps {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  storedGamifyDetails?: IGamify;
}

const GamifyComponent = ({
  removeComponent,
  componentKey,
  storedGamifyDetails,
}: IGamificationComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const styles = stylesProvider(isDarkMode);
  const nodeRef = React.useRef(null);
  const [isDraggingDisabled, setIsDraggingDisabled] = useState(false);
  const [numberOfCompletedMissions, setNumberOfCompletedMissions] = useState(0);
  const [totalJoyPoints, setTotalJoyPoints] = useState(0);
  const [fireConfetti, setFireConfetti] = useState(false);
  const [activeMissions, setActiveMissions] = useState<IMission[]>(
    storedGamifyDetails?.missions
      ? [...storedGamifyDetails?.missions]
      : [{ missionText: "clean my room", joyPoints: 500, missionKey: 0 }]
  );

  const [CompletedMissions, setCompletedMissions] = useState<IMission[]>([]);

  const [rewards, setRewards] = useState<IReward[]>(
    storedGamifyDetails?.rewards || [
      { rewardText: "movie ticket", joyPoints: 50, rewardKey: 0 },
    ]
  );

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedGamifyDetails?.positionX!,
    y: storedGamifyDetails?.positionY!,
  });

  useEffect(() => {
    ls.set<IGamify>(`gamify_${componentKey}`, {
      positionX: position?.x!,
      positionY: position?.y!,
      numberOfCompletedMissions: numberOfCompletedMissions,
      totalNumberOfPoints: totalJoyPoints,
      missions: activeMissions,
      rewards: rewards,
    });
  }, [
    componentKey,
    activeMissions,
    numberOfCompletedMissions,
    position?.x,
    position?.y,
    rewards,
    totalJoyPoints,
  ]);

  const onMissionAccomplished = useCallback(
    (key: number) => {
      const accomplishedMission = activeMissions.find(
        (m) => m?.missionKey === key
      );
      setActiveMissions((missions) =>
        missions.filter((mission) => mission !== accomplishedMission)
      );
      setCompletedMissions((missions) => [...missions, accomplishedMission!]);
      setNumberOfCompletedMissions((cm) => cm + 1);
      setFireConfetti((value) => !value);
    },
    [activeMissions]
  );

  const onMissionRetreived = useCallback(
    (key: number) => {
      const accomplishedMission = CompletedMissions.find(
        (m) => m?.missionKey === key
      );
      setActiveMissions((missions) => [...missions, accomplishedMission!]);
      setCompletedMissions((missions) =>
        missions.filter((mission) => mission !== accomplishedMission)
      );
      setNumberOfCompletedMissions((cm) => cm - 1);
    },
    [CompletedMissions]
  );

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [position.x, position.y]
  );

  const createMission = useCallback(
    (
      missionText: string,
      joyPoints: number,
      key: number,
      completed: boolean
    ) => {
      return (
        <MissionComponent
          key={key}
          onMissionAccomplished={onMissionAccomplished}
          onMissionRetrieved={onMissionRetreived}
          missionText={missionText}
          joyPoints={joyPoints}
          missionKey={key}
          parentComponentKey={componentKey}
          completedMission={completed}
        />
      );
    },
    [componentKey, onMissionAccomplished, onMissionRetreived]
  );

  const createReward = useCallback(
    (rewardText: string, joyPoints: number, rewardKey: number) => {
      return (
        <RewardsComponent
          key={rewardKey}
          rewardText={rewardText}
          joyPoints={joyPoints}
          rewardKey={rewardKey}
        />
      );
    },
    []
  );

  return (
    <Draggable
      nodeRef={nodeRef}
      onStop={onStop}
      defaultPosition={
        storedGamifyDetails && position
          ? { x: position.x, y: position.y }
          : undefined
      }
      disabled={isDraggingDisabled}
    >
      <Box>
        <Box style={styles.container}>
          <Box style={styles.missionSection}>
            <span
              style={styles.completedMissions}
            >{`Total # of completed missions ${numberOfCompletedMissions}`}</span>

            <ContentContainer styles={styles.missionsOrRewardsContainer}>
              <ContentContainer styles={styles.missionsOrRewardsStack}>
                {activeMissions.map((mission, key) =>
                  createMission(
                    mission?.missionText,
                    mission?.joyPoints,
                    key,
                    false
                  )
                )}
              </ContentContainer>
            </ContentContainer>
            <ContentContainer
              styles={{
                textAlign: "center",
                position: "relative",
                top: 5,
              }}
            >
              <IconButton
                sx={{
                  zIndex: 1,
                }}
                onClick={() =>
                  setActiveMissions((missions) => [
                    ...missions,
                    {
                      missionText: "",
                      joyPoints: 0,
                      missionKey: activeMissions.length,
                    },
                  ])
                }
              >
                <Add />
              </IconButton>
            </ContentContainer>
            <ContentContainer>
              <span style={{ position: "relative", left: 10 }}>
                {"Completed missions"}
              </span>
            </ContentContainer>
            <ContentContainer styles={styles.missionsOrRewardsContainer}>
              <ContentContainer styles={styles.missionsOrRewardsStack}>
                {CompletedMissions.map((cm) =>
                  createMission(
                    cm?.missionText,
                    cm?.joyPoints,
                    cm?.missionKey,
                    true
                  )
                )}
              </ContentContainer>
            </ContentContainer>
          </Box>
          <Box style={styles.rewardsSection}>
            <ContentContainer styles={styles.optionsContainer}>
              <OptionsComponent
                key={Math.random()}
                dark={isDarkMode}
                componentKey={componentKey}
                componentType={componentTypes.gamify}
                removeComponent={removeComponent}
              />
            </ContentContainer>
            <span
              style={styles.totalPoints}
            >{`Total # of points ${totalJoyPoints} jp`}</span>

            <ContentContainer styles={styles.missionsOrRewardsContainer}>
              <ContentContainer styles={styles.missionsOrRewardsStack}>
                {rewards.map((reward, key) =>
                  createReward(reward.rewardText, reward.joyPoints, key)
                )}
              </ContentContainer>
            </ContentContainer>
          </Box>
        </Box>
        <ReactCanvasConfetti
          style={{ width: 700, height: 400, position: "relative", top: 40 }}
          fire={true && fireConfetti}
        />
      </Box>
    </Draggable>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    container: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      height: "fit-content",
      background: "#7ddcff",
      minHeight: 400,
      width: 750,
      position: "absolute",
      top: "10%",
      left: 10,
    },
    missionSection: {
      background: "#f2ff68",
      gridColumnStart: "1",
    },
    rewardsSection: {
      gridColumnStart: "2",
    },
    totalPoints: {
      flexDirection: "row",
      display: "flex",
      padding: 20,
    },
    optionsContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      display: "flex",
      float: "right",
      zIndex: 1,
    },
    completedMissions: {
      flexDirection: "row",
      justifyContent: "flex-start",
      display: "flex",
      padding: "20px 20px 20px 10px",
    },
    missionsOrRewardsContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      zIndex: 1,
    },
    missionsOrRewardsStack: {
      alignContent: "space-between",
      display: "block",
      zIndex: 1,
    },
  };
};

export default GamifyComponent;
