import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AppContext } from "../../App";
import {
  ColorMap,
  Colors,
  componentTypes,
  INotes,
  MyStyleSheet,
} from "../../models";
import ContentContainer from "../ContentContainer";
import * as ls from "local-storage";
import { Resizable, ResizeCallbackData } from "react-resizable";
import OptionsComponent from "../Options/OptionsComponent";
import "./NotesComponent.css";

interface INotesComponentProps {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  storedNotesDetails?: INotes;
}

const NotesComponent = ({
  removeComponent,
  componentKey,
  storedNotesDetails,
}: INotesComponentProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDraggingDisabled, setIsDraggingDisabled] = useState(false);
  const { isDarkMode } = useContext(AppContext);
  const [notesContent, setNotesContent] = useState(
    storedNotesDetails?.notesContent || " "
  );
  const [colorIndex, setColorIndex] = useState(
    storedNotesDetails?.notesColorIndex || 0
  );
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedNotesDetails?.positionX!,
    y: storedNotesDetails?.positionY!,
  });

  const styles = stylesProvider(ColorMap[colorIndex]);
  const [state, setState] = useState({ width: 320, height: 200 });

  const nodeRef = React.useRef(null);

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [position.x, position.y]
  );

  useEffect(() => {
    ls.set<INotes>(`notes_${componentKey}`, {
      notesContent: notesContent,
      notesColorIndex: colorIndex,
      positionX: position?.x!,
      positionY: position?.y!,
    });
  }, [colorIndex, componentKey, notesContent, position?.x, position?.y]);

  const onResize = (
    event: React.SyntheticEvent<Element, Event>,
    { node, size, handle }: ResizeCallbackData
  ) => {
    setState({
      width: size.width,
      height: size.height,
    });
  };

  return (
    <Draggable
      nodeRef={nodeRef}
      onStop={onStop}
      defaultPosition={
        storedNotesDetails && position
          ? { x: position.x, y: position.y }
          : undefined
      }
      disabled={isDraggingDisabled}
    >
      <Resizable width={state.width} height={state.height} onResize={onResize}>
        <Box style={styles.noteBook}>
          <TextField
            className="Notebook"
            style={styles.notes}
            value={notesContent}
            onChange={(e) => setNotesContent(e.target.value)}
            multiline={true}
            onClick={() => setIsDraggingDisabled(true)}
            onMouseLeave={() => setIsDraggingDisabled(false)}
          />
          <ContentContainer
            styles={{
              width: "103%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              position: "absolute",
              top: 4,
            }}
          >
            <OptionsComponent
              key={Math.random()}
              dark={isDarkMode}
              componentKey={componentKey}
              componentType={componentTypes.notes}
              removeComponent={removeComponent}
              menuOptions={[
                ...Colors.map((color, index) => ({
                  label: color,
                  onClickFunction: () => setColorIndex(index),
                })),
              ]}
            />
          </ContentContainer>
        </Box>
      </Resizable>
    </Draggable>
  );
};

const stylesProvider = (color: string): MyStyleSheet => {
  return {
    noteBook: {
      height: "fit-content",
      minHeight: 400,
      width: 350,
      position: "absolute",
      top: "10%",
      left: 10,
      borderRadius: 25,
    },
    notes: {
      height: "fit-content",
      minHeight: 396,
      width: 368,
      background: color,
      borderRadius: 25,
    },
  };
};

export default NotesComponent;
