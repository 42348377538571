import * as ls from "local-storage";
import { ChangeEvent, useCallback, useContext, useState } from "react";
import { AppContext } from "../../App";
import Mission from "../../assets/Mission.svg";
import { IGamify, MyStyleSheet } from "../../models";
import ContentContainer from "../ContentContainer";
import "./Gamify.css";

import { Checkbox, ClickAwayListener, TextField } from "@mui/material";

interface IMissionComponentProps {
  missionText: string;
  joyPoints: number;
  missionKey: number;
  parentComponentKey: number;
  completedMission: boolean;
  onMissionAccomplished: (key: number) => void;
  onMissionRetrieved: (key: number) => void;
}

const MissionComponent = ({
  missionText,
  joyPoints,
  missionKey: key,
  completedMission,
  parentComponentKey,
  onMissionAccomplished,
  onMissionRetrieved,
}: IMissionComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const styles = stylesProvider(isDarkMode);
  const [missionTextContent, setMissionTextContent] = useState(missionText);
  const [isTextboxDisplayed, setIsTextboxDisplayed] = useState(
    missionText?.length === 0
  );
  const [jp, setJp] = useState(joyPoints);
  const [isJoyPointsEntryEnabled, setIsJoyPointsEntryEnabled] = useState(false);
  const [checked, setChecked] = useState(completedMission);

  const displayMissionEntry = useCallback(() => {
    setIsTextboxDisplayed(true);
  }, []);

  const displayJoyPointsEntry = useCallback(() => {
    setIsJoyPointsEntryEnabled(true);
  }, []);

  const onCheck = useCallback(() => {
    if (!checked) onMissionAccomplished(key);
    if (checked) onMissionRetrieved(key);
    setChecked((value) => !value);
  }, [checked, key, onMissionAccomplished, onMissionRetrieved]);

  const onUpdateMissionText = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const storedDetails = ls.get<IGamify>(`gamify_${parentComponentKey}`);
      const specificMissionIndex = storedDetails.missions.findIndex(
        (mission) => mission.missionKey === key
      );
      const substiteMission = {
        ...storedDetails.missions[specificMissionIndex],
        missionText: e.target.value,
      };
      storedDetails.missions[specificMissionIndex] = substiteMission;
      ls.set<IGamify>(`gamify_${parentComponentKey}`, {
        ...storedDetails,
      });

      setMissionTextContent(e.target.value);
    },
    [key, parentComponentKey]
  );

  const onUpdateJoyPoints = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const storedDetails = ls.get<IGamify>(`gamify_${parentComponentKey}`);
      const specificMissionIndex = storedDetails.missions.findIndex(
        (mission) => mission.missionKey === key
      );
      const substiteMission = {
        ...storedDetails.missions[specificMissionIndex],
        joyPoints: +e.target.value >= 0 ? +e.target.value : 0,
      };
      storedDetails.missions[specificMissionIndex] = substiteMission;
      ls.set<IGamify>(`gamify_${parentComponentKey}`, {
        ...storedDetails,
      });

      setJp(+e.target.value >= 0 ? +e.target.value : 0);
    },
    [key, parentComponentKey]
  );

  return (
    <ContentContainer styles={styles.container}>
      <ContentContainer styles={styles.mission}>
        <img style={{ width: 50 }} src={Mission} alt={"mission icon"} />

        {isTextboxDisplayed && (
          <ClickAwayListener
            onClickAway={() =>
              setIsTextboxDisplayed(missionTextContent.length === 0)
            }
          >
            <TextField
              style={styles.textField}
              variant="standard"
              onChange={(e) => {
                onUpdateMissionText(e);
              }}
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? setIsTextboxDisplayed(missionTextContent.length === 0)
                  : null
              }
            />
          </ClickAwayListener>
        )}
        {!isTextboxDisplayed && (
          <span
            style={styles.missionText}
            onClick={displayMissionEntry}
          >{`${missionTextContent}`}</span>
        )}

        {isJoyPointsEntryEnabled && (
          <ClickAwayListener
            onClickAway={() => setIsJoyPointsEntryEnabled(jp < 0)}
          >
            <TextField
              style={styles.joyPointsTextField}
              variant="standard"
              onChange={(e) => onUpdateJoyPoints(e)}
              onKeyDown={(e) =>
                e.key === "Enter" ? setIsJoyPointsEntryEnabled(jp < 0) : null
              }
            />
          </ClickAwayListener>
        )}
        {!isJoyPointsEntryEnabled && (
          <span
            onClick={displayJoyPointsEntry}
            style={styles.joyPoints}
          >{`${jp}`}</span>
        )}
        <span>&nbsp;{`jp`}</span>
      </ContentContainer>
      <Checkbox
        style={styles.checkbox}
        checked={checked}
        onClick={onCheck}
        onTouchStart={onCheck}
        className={"Checkbox"}
      />
    </ContentContainer>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    container: {
      display: "flex",
      zIndex: 1,
    },
    mission: {
      background: "white",
      minHeight: 60,
      width: 300,
      display: "flex",
      alignItems: "center",
      padding: 5,
      marginTop: 5,
    },
    checkbox: {
      float: "right",
      backgroundColor: "transperant",
      background: `none !important`,
      transform: `none !important`,
      position: "relative",
      left: 5,
    },

    joyPoints: {
      display: "flex",
      justifyContent: "center",
      justifySelf: "center",
      padding: 4,
      alignItems: "center",
      border: "1px solid black",
      borderRadius: 2,
    },
    missionText: {
      maxWidth: 190,
      minWidth: 190,
      overflowWrap: "break-word",
    },
    textField: {
      maxWidth: 190,
      paddingRight: 5,
      cursor: "pointer",
    },
    joyPointsTextField: {
      maxWidth: 40,
      paddingRight: 5,
    },
  };
};

export default MissionComponent;
