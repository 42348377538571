import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Avatar, IconButton } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { ColorMap, HabitIconTypes, Icons, MyStyleSheet } from "../../../models";
import ContentContainer from "../../ContentContainer";
import { RenderHabitIcon } from "../../Habit/Habit.utils";

interface IChangableAvatarProps {
  setIconType: (iconType: HabitIconTypes) => void;
  setIconColor: (iconColor: string) => void;

  currentIconType: HabitIconTypes;
}

export const ChangableAvatar = ({
  setIconType,
  setIconColor,
  currentIconType,
}: IChangableAvatarProps) => {
  const { isDarkMode } = useContext(AppContext);
  const styles = stylesProvider(isDarkMode);

  const [iconIndex, setIconIndex] = useState(
    currentIconType
      ? Icons.findIndex((icon) => icon === currentIconType)
      : Math.floor(Math.random() * 5) + 1
  );

  useEffect(() => {
    setIconColor(ColorMap[iconIndex]);
    setIconType(Icons[iconIndex]);
    return () => {
      setIconColor(ColorMap[iconIndex]);
      setIconType(Icons[iconIndex]);
    };
  }, [iconIndex, setIconColor, setIconType]);

  const navigateForward = useCallback(() => {
    setIconIndex((prev) => (prev === Icons.length - 1 ? 0 : prev + 1));
  }, []);

  const navigateBackward = useCallback(() => {
    setIconIndex((prev) => (prev === 0 ? Icons.length - 1 : prev - 1));
  }, []);

  return (
    <ContentContainer
      styles={{ display: "flex", flexDirection: "row", alignContent: "center" }}
    >
      <IconButton
        style={styles.iconButtonLeft}
        onClick={navigateBackward}
        onTouchStart={navigateBackward}
      >
        <ArrowLeftIcon />
      </IconButton>
      <Avatar
        sx={{
          width: 50,
          height: 50,
          bgcolor: iconIndex === 0 ? ColorMap[4] : ColorMap[iconIndex],
        }}
      >
        {RenderHabitIcon(Icons[iconIndex])}
      </Avatar>
      <IconButton
        style={styles.iconButtonRight}
        onClick={navigateForward}
        onTouchStart={navigateForward}
      >
        <ArrowRightIcon />
      </IconButton>
    </ContentContainer>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    iconButtonLeft: {
      color: isDarkMode ? "white" : "#121212",
    },
    iconButtonRight: {
      color: isDarkMode ? "white" : "#121212",
    },
  };
};

export default ChangableAvatar;
