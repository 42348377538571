import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useContext } from "react";
import { AppContext } from "../../App";
import { componentTypes } from "../../models";

interface ImenuOption {
  label: string;
  onClickFunction: () => void;
}

interface IOptionsComponentProps {
  menuOptions?: ImenuOption[];
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentType: componentTypes;
  componentKey: number;
  dark?: boolean;
  light?: boolean;
}

const OptionsComponent = ({
  menuOptions,
  removeComponent,
  componentKey,
  componentType,
  dark,
  light,
}: IOptionsComponentProps) => {
  const [anchorElement, setAnchorElement] = React.useState(null);
  const { isDarkMode } = useContext(AppContext);
  const open = Boolean(anchorElement);
  const handleClick = (event: any) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        onTouchStart={handleClick}
        style={{
          marginBottom: -20,
          color: light ? "white" : !dark && isDarkMode ? "white" : "#121212",
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuOptions?.map((option, index) => (
          <MenuItem key={index} onClick={option.onClickFunction}>
            {option.label}
          </MenuItem>
        ))}
        <MenuItem onClick={() => removeComponent(componentKey, componentType)}>
          Remove ❌
        </MenuItem>
      </Menu>
    </>
  );
};

export default OptionsComponent;
