export interface MyStyleSheet {
  [key: string]: React.CSSProperties | undefined;
}

export enum loadState {
  initial,
  loading,
  loaded,
}

export enum componentTypes {
  habit = "habit",
  todo = "todo",
  timer = "timer",
  counter = "counter",
  dailies = "dailies",
  vision = "vision",
  notes = "notes",
  step = "step",
  goal = "goal",
  gamify = "gamify",
  all = "all",
}

export enum HabitIconTypes {
  meditation = "meditation",
  focus = "focus",
  workout = "workout",
  read = "read",
}

export const Icons = [
  HabitIconTypes.meditation,
  HabitIconTypes.focus,
  HabitIconTypes.workout,
  HabitIconTypes.read,
];

export interface IHabit {
  name: string;
  done: boolean;
  index: number;
  id: string;
  iconType: HabitIconTypes;
  iconColor: string;
}

export interface IHabitTracker {
  isDisplayed: boolean;
}

export interface ICounter {
  counterName: string;
  counterValue: number;
  positionX?: number;
  positionY?: number;
}

export type timerState = "stopped" | "started" | "paused";

export interface ITimer {
  timeLeft: string;
  time: string;
  timerState: timerState;
  positionX?: number;
  positionY?: number;
}

export interface INotes {
  notesContent: string;
  notesColorIndex: number;
  positionX?: number;
  positionY?: number;
}

export interface ITodoItem {
  todoText: string;
  checked?: boolean;
  todoKey: number;
}

export interface ITodo {
  todoItems: ITodoItem[];
  positionX?: number;
  positionY?: number;
}

export interface IMission {
  missionText: string;
  joyPoints: number;
  missionKey: number;
}

export interface IReward {
  rewardText: string;
  joyPoints: number;
  rewardKey: number;
}

export interface ITracker {
  positionX?: number;
  positionY?: number;
}

export interface ICalendar {
  streakDays: string[];
}

export interface IGoal {
  goalName: string;
  todoItems?: ITodoItem[];
  step?: any;
  positionX?: number;
  positionY?: number;
}

export interface IDailies {
  positionX?: number;
  positionY?: number;
  habits?: IHabit[];
  habitTrackersDisplayed?: boolean[];
  habitTrackersStoredDetails?: ITracker[];
}

export interface IGamify {
  positionX?: number;
  positionY?: number;
  numberOfCompletedMissions: number;
  totalNumberOfPoints: number;
  missions: IMission[];
  rewards: IReward[];
}

export const Colors: string[] = [
  "white ⚪",
  "orange 🟠",
  "purple 🟣",
  "green 🟢",
  "blue 🔵",
  "red 🔴",
];

export enum ColorMap {
  "antiquewhite",
  "#fab535",
  "#e68dee",
  "#b8ea7b",
  "#82bae9",
  "#f9310e",
}
