import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, ClickAwayListener, TextField } from "@mui/material";
import * as ls from "local-storage";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { AppContext } from "../../App";
import AddButtonSmall from "../../assets/AddButtonSmall.svg";
import { componentTypes, IGoal, MyStyleSheet } from "../../models";
import ContentContainer from "../ContentContainer";
import OptionsComponent from "../Options/OptionsComponent";
import TodoItem from "../Todo/TodoItem";

interface StepItemInterface {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  DisableDragging: (enabled: boolean) => void;
  componentKey: number;
  AddGoalStep?: () => void;
  displayStep?: boolean;
  leftMargin?: number;
  storedGoalDetails?: IGoal;
}
const StepItem = ({
  removeComponent,
  componentKey,
  AddGoalStep,
  displayStep,
  leftMargin,
  DisableDragging,
  storedGoalDetails,
}: StepItemInterface) => {
  const { isDarkMode } = useContext(AppContext);
  const styles = stylesProvider(isDarkMode, leftMargin!);
  const { goalName, todoItems } = storedGoalDetails || {};
  const [GoalName, SetGoalName] = useState(goalName ?? "");
  const goalSteps = [];
  const [isInputFieldDisplayed, setisInputFieldDisplayed] = useState(
    goalName ? false : true
  );
  const [todoListItems, setTodoListItems] = useState<JSX.Element[]>([]);
  const [isHoveredOver, setIsHoveredOver] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(
    todoItems?.filter((x) => !x.checked).length === 0
  );
  const initialLoad = useRef(true);
  const ref = useRef<HTMLDivElement>();

  const selectText = (
    event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>
  ): void => {
    event.currentTarget.select();
  };

  const removeTodo = useCallback(
    (key: number) => {
      setTodoListItems((prevItems) => [
        ...prevItems.filter((x) => x.key !== key.toString()),
      ]);
      const updatedTodos = ls
        .get<IGoal>(`goal_${componentKey}`)
        .todoItems?.filter((x) => x!.todoKey !== key);
      ls.set<IGoal>(`goal_${componentKey}`, {
        ...ls.get<IGoal>(`goal_${componentKey}`),
        todoItems: updatedTodos,
      });
    },
    [componentKey]
  );

  const updateTodo = useCallback(
    (key: number, checked?: boolean, updatedTodoText?: string) => {
      const storedGoalTodos = ls.get<IGoal>(`goal_${componentKey}`).todoItems;
      const foundItemIndex = storedGoalTodos?.findIndex(
        (item) => item.todoKey === key
      );

      const storedCheck =
        !!foundItemIndex &&
        storedGoalTodos &&
        storedGoalTodos[foundItemIndex].checked;
      const value = storedCheck === undefined ? false : checked;

      if (foundItemIndex !== undefined && foundItemIndex !== -1) {
        const foundTodo = storedGoalTodos![foundItemIndex];
        storedGoalTodos![foundItemIndex] = {
          ...foundTodo,
          checked: checked || value,
          todoText: updatedTodoText ?? foundTodo.todoText,
        };
      }

      if (storedGoalTodos?.filter((x) => !x.checked).length === 0) {
        setFireConfetti(true);
      } else {
        setFireConfetti(false);
      }

      ls.set<IGoal>(`goal_${componentKey}`, {
        ...ls.get<IGoal>(`goal_${componentKey}`),
        todoItems: storedGoalTodos!,
      });
    },
    [componentKey]
  );

  useEffect(() => {
    if (initialLoad.current && todoItems?.length! > 0) {
      initialLoad.current = false;
      const todoItemsList = todoItems?.map((item) => (
        <TodoItem
          updateTodo={updateTodo}
          storedCheck={item.checked}
          originalTodoText={item.todoText}
          removeTodo={removeTodo}
          key={item.todoKey}
          todoKey={item.todoKey}
        />
      ));

      setTodoListItems(todoItemsList!);
    }
  }, [removeTodo, todoItems, updateTodo]);

  const onAddTodo = useCallback(() => {
    const key = Math.random();

    setTodoListItems((prevItems) => [
      ...prevItems,
      <TodoItem
        removeTodo={removeTodo}
        key={key}
        todoKey={key}
        updateTodo={updateTodo}
      />,
    ]);
    const storedGoalTodos = ls.get<IGoal>(`goal_${componentKey}`).todoItems;

    ls.set<IGoal>(`goal_${componentKey}`, {
      ...ls.get<IGoal>(`goal_${componentKey}`),
      todoItems: [...(storedGoalTodos ?? []), { todoKey: key, todoText: "" }],
    });
  }, [componentKey, removeTodo, updateTodo]);

  return (
    <Box style={styles.goal}>
      <ReactCanvasConfetti
        width={300}
        height={100}
        style={{ bottom: 30, position: "relative" }}
        fire={true && fireConfetti}
      />
      <Box
        sx={{
          bgcolor: "background.paper",
          border: 2,
          minHeight: 80,
          height: "fit-content",
          borderRadius: 1,
          borderColor: isDarkMode ? "white" : "grey",
          position: "relative",
          top: 100,
          right: -25,
        }}
        ref={ref}
        style={styles.goal}
        onMouseEnter={() => setIsHoveredOver(true)}
      >
        <ContentContainer
          styles={{
            width: 290,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <OptionsComponent
            key={componentKey}
            componentKey={componentKey}
            removeComponent={removeComponent}
            componentType={
              goalSteps.length === 0 ? componentTypes.goal : componentTypes.step
            }
            dark
          />
        </ContentContainer>
        {isInputFieldDisplayed && (
          <ContentContainer
            styles={{
              width: "100%",
              height: 80,
              alignItems: "center",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              overflow: "clip",
            }}
          >
            <ClickAwayListener
              onClickAway={() =>
                setisInputFieldDisplayed(GoalName.length === 0)
              }
            >
              <TextField
                defaultValue={GoalName}
                variant="standard"
                placeholder="Enter Goal Text"
                onChange={(e) => {
                  SetGoalName(e.target.value);
                  ls.set<IGoal>(`goal_${componentKey}`, {
                    ...ls.get<IGoal>(`goal_${componentKey}`),
                    goalName: e.target.value,
                  });
                }}
                inputProps={{
                  onDoubleClick: selectText,
                }}
                onMouseEnter={() => DisableDragging(true)}
                onMouseLeave={() => DisableDragging(false)}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? setisInputFieldDisplayed(GoalName.length === 0)
                    : null
                }
              />
            </ClickAwayListener>
          </ContentContainer>
        )}

        {!isInputFieldDisplayed && (
          <h2
            onTouchStart={() => setisInputFieldDisplayed(true)}
            onDrag={() => {
              setisInputFieldDisplayed(false);
            }}
            onDoubleClick={() => {
              setisInputFieldDisplayed(true);
            }}
            style={styles.text}
          >
            {GoalName}
            {fireConfetti && (
              <CheckCircleIcon
                style={{
                  fontSize: 25,
                  color: "green",
                  top: 4,
                  position: "relative",
                  left: 10,
                }}
              />
            )}
          </h2>
        )}
        <ContentContainer
          styles={
            isInputFieldDisplayed
              ? { top: 30, marginBottom: 40, position: "relative" }
              : {}
          }
        >
          {todoListItems}
        </ContentContainer>

        {!isInputFieldDisplayed && isHoveredOver && (
          <img
            src={AddButtonSmall}
            alt={"Add Component Button"}
            onClick={onAddTodo}
            style={{
              position: "absolute",
              flexDirection: "row",
              justifySelf: "center",
              alignItems: "center",
              bottom: -23,
              left: "43%",
              zIndex: 1,
            }}
          />
        )}
      </Box>
      {/* {displayOptionsList && (
        <ContentContainer styles={styles.optionsList}>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={AddGoalStep}>
                <ListItemText primary="Add Step" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={onAddTodo}>
                <ListItemText primary="Add Todo" />
              </ListItemButton>
            </ListItem>
          </List>
        </ContentContainer>
      )} */}
      {displayStep && (
        <Box
          sx={{
            bgcolor: "background.paper",
            border: 2,
            minWidth: 60,
            minHeight: 3,
            borderColor: isDarkMode ? "white" : "grey",
            position: "relative",
            top: 40,
            right: 46,
          }}
        />
      )}
    </Box>
  );
};

const stylesProvider = (
  isDarkMode: boolean,
  leftMargin: number
): MyStyleSheet => {
  return {
    goal: {
      height: "fit-content",
      width: "fit-content",
      position: "absolute",
      top: "10%",
      left: leftMargin ?? 10,
    },
    text: {
      flexDirection: "row",
      alignContent: "center",
      textAlign: "center",
      overflow: "hidden",
      maxWidth: "290px",
      wordBreak: "break-word",
      marginTop: 0,
    },
    textField: {
      width: "80%",
      display: "flex",
      alignSelf: "center",
      justifySelf: "center",
    },
  };
};

export default StepItem;
