import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  ClickAwayListener,
  IconButton,
  TextField,
} from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { AppContext } from "../../../App";
import { HabitIconTypes, IHabit } from "../../../models";
import ContentContainer from "../../ContentContainer";
import ChangableAvatar from "../../Shared/Changeable Avatar/ChangableAvatar";

interface IHabitComponentProps {
  habit: IHabit;
  removeHabit: (habitId: string) => void;
  setDisplayHabitTracker: (habitId: string) => void;
  storedHabitDetails?: IHabit;
  onHabitDone: (habitId: string) => void;
  isHabitTrackerDisplayed: boolean;
  updateHabit: (
    habitId: string,
    habitName?: string,
    iconType?: HabitIconTypes,
    iconColor?: string
  ) => void;
}

const HabitComponent = ({
  habit,
  removeHabit,
  setDisplayHabitTracker,
  updateHabit,
  isHabitTrackerDisplayed,
  onHabitDone,
  storedHabitDetails,
}: IHabitComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const [isInputFieldDisplayed, setisInputFieldDisplayed] = useState(
    habit.name ? false : true
  );
  const [habitName, setHabitName] = useState(habit.name ?? "");

  const selectText = (
    event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>
  ): void => {
    event.currentTarget.select();
  };

  const setIconType = useCallback(
    (iconType: HabitIconTypes) => {
      updateHabit(habit.id, habit.name, iconType, habit.iconColor);
    },
    [habit.iconColor, habit.id, habit.name, updateHabit]
  );

  const setIconColor = useCallback(
    (iconColor: string) => {
      updateHabit(habit.id, habit.name, habit.iconType, iconColor);
    },
    [habit.iconType, habit.id, habit.name, updateHabit]
  );

  return (
    <>
      <ContentContainer>
        <IconButton
          id="basic-button"
          style={{
            color: isDarkMode ? "white" : "black",
            position: "relative",
            display: "flex",
            width: 10,
            left: "45%",
            flexDirection: "row",
            justifySelf: "center",
          }}
          onTouchStart={() => {
            removeHabit(habit.id);
          }}
          onClick={() => {
            removeHabit(habit.id);
          }}
        >
          <CloseIcon sx={{ position: "relative", left: 5 }} />
        </IconButton>

        <ContentContainer
          styles={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <ChangableAvatar
            currentIconType={habit.iconType}
            setIconType={setIconType}
            setIconColor={setIconColor}
          />

          {isInputFieldDisplayed && (
            <ContentContainer
              styles={{
                height: 60,
                position: "relative",
                alignItems: "center",
                justifySelf: "center",
                display: "flex",
                flexDirection: "column",
                overflow: "clip",
              }}
            >
              <ClickAwayListener
                onClickAway={() =>
                  setisInputFieldDisplayed(habitName.length === 0)
                }
              >
                <TextField
                  style={{
                    position: "relative",
                    top: 10,
                  }}
                  className={isDarkMode ? "TextFieldDarkMode" : "TextField"}
                  sx={{
                    position: "relative",
                    marginBottom: 20,
                    textAlign: "center",
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                    onDoubleClick: selectText,
                  }}
                  variant="standard"
                  placeholder="Habit Name"
                  value={habitName}
                  onChange={(e) => {
                    setHabitName(e.target.value);
                    updateHabit(habit.id, e.target.value, habit.iconType);
                    // ls.set<Ihabit>(`habit_${habit.id}`, {
                    //   ...ls.get<Ihabit>(`habit_${habit.id}`),
                    //   name: e.target.value,
                    // });
                  }}
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? setisInputFieldDisplayed(habitName.length === 0)
                      : null
                  }
                />
              </ClickAwayListener>
            </ContentContainer>
          )}

          {!isInputFieldDisplayed && (
            <span
              style={{
                fontFamily: "sans-serif",
                color: isDarkMode ? "white" : "black",
                fontSize: 18,
                marginTop: 10,
                marginBottom: 10,
                padding: 5,
              }}
              onTouchStart={() => setisInputFieldDisplayed(true)}
              onDrag={() => {
                setisInputFieldDisplayed(false);
              }}
              onDoubleClick={() => {
                setisInputFieldDisplayed(true);
              }}
            >
              {habit.name}
            </span>
          )}
          <Button
            onClick={() => onHabitDone(habit.id)}
            variant={habit.done ? "contained" : "outlined"}
          >
            Done
          </Button>
          <span
            onClick={() => {
              setDisplayHabitTracker(habit.id);
            }}
            style={{ cursor: "pointer", position: "relative", top: 10 }}
          >
            {isHabitTrackerDisplayed ? "hide" : "show"} tracker
          </span>
        </ContentContainer>
      </ContentContainer>
    </>
  );
};

export default HabitComponent;
