import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Box, ClickAwayListener, IconButton, TextField } from "@mui/material";
import * as ls from "local-storage";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AppContext } from "../../App";
import { componentTypes, ICounter, MyStyleSheet } from "../../models";
import ContentContainer from "../ContentContainer";
import OptionsComponent from "../Options/OptionsComponent";

import "./CounterComponent.css";

interface ICounterComponentProps {
  removeComponent: (
    componentKey: number,
    componentType: componentTypes
  ) => void;
  componentKey: number;
  storedCounterDetails?: ICounter;
}

const CounterComponent = ({
  removeComponent,
  componentKey,
  storedCounterDetails,
}: ICounterComponentProps) => {
  const [counterName, SetcounterName] = useState(
    storedCounterDetails?.counterName || ""
  );
  const [isInputFieldDisplayed, setisInputFieldDisplayed] = useState(
    storedCounterDetails?.counterName ? false : true
  );
  const [counter, setCounter] = useState<number>(
    storedCounterDetails?.counterValue || 0
  );

  const [isHoveredOver, setIsHoveredOver] = useState(false);

  const { isDarkMode } = useContext(AppContext);
  const styles = stylesProvider(isDarkMode);
  const nodeRef = React.useRef(null);

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: storedCounterDetails?.positionX || 0,
    y: storedCounterDetails?.positionY || 0,
  });

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }
    },
    [position.x, position.y]
  );

  useEffect(() => {
    ls.set<ICounter>(`counter_${componentKey}`, {
      counterName: counterName,
      counterValue: counter,
      positionX: position?.x!,
      positionY: position?.y!,
    });
  }, [counterName, componentKey, counter, position?.x, position?.y]);

  const selectText = (
    event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>
  ): void => {
    event.currentTarget.select();
  };

  return (
    <Draggable
      nodeRef={nodeRef}
      onStop={onStop}
      defaultPosition={
        storedCounterDetails && position
          ? { x: position.x, y: position.y }
          : undefined
      }
    >
      <Box
        ref={nodeRef}
        style={styles.counter}
        onMouseEnter={() => setIsHoveredOver(true)}
        onMouseLeave={() => setIsHoveredOver(false)}
      >
        <ContentContainer
          styles={{
            width: "98%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <OptionsComponent
            key={Math.random()}
            componentKey={componentKey}
            removeComponent={removeComponent}
            componentType={componentTypes.counter}
          />
        </ContentContainer>
        {isInputFieldDisplayed && (
          <ContentContainer
            styles={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <ClickAwayListener
              onClickAway={() =>
                setisInputFieldDisplayed(counterName.length === 0)
              }
            >
              <TextField
                style={styles.textField}
                defaultValue={counterName}
                className={isDarkMode ? "TextFieldDarkMode" : "TextField"}
                variant="standard"
                onChange={(e) => SetcounterName(e.target.value)}
                inputProps={{
                  onDoubleClick: selectText,
                }}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? setisInputFieldDisplayed(counterName.length === 0)
                    : null
                }
              />
            </ClickAwayListener>
          </ContentContainer>
        )}
        {!isInputFieldDisplayed && (
          <h2
            onTouchStart={() => setisInputFieldDisplayed(true)}
            onClick={() => {
              setisInputFieldDisplayed(true);
            }}
            style={styles.text}
          >
            {counterName}
          </h2>
        )}
        <h1 style={{ ...styles.text, marginBottom: 30 }}> {counter} </h1>
        {!isHoveredOver ? (
          <> </>
        ) : (
          <ContentContainer styles={styles.stack}>
            <IconButton
              style={{
                color: isDarkMode ? "white" : "#121212",
                float: "right",
                bottom: 80,
                left: 60,
              }}
              onClick={() => setCounter((value) => ++value)}
              onTouchStart={() => setCounter((value) => ++value)}
            >
              <ArrowCircleUpIcon />
            </IconButton>
            <IconButton
              style={{
                color: isDarkMode ? "white" : "#121212",
                float: "right",
                bottom: 50,
                left: 20,
                fontSize: 40,
              }}
              onClick={() => setCounter((value) => --value)}
              onTouchStart={() => setCounter((value) => --value)}
            >
              <ArrowCircleDownIcon />
            </IconButton>
          </ContentContainer>
        )}
      </Box>
    </Draggable>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    counter: {
      width: "180px",
      height: "fit-content",
      borderRadius: "25px",
      backgroundColor: "#ed8d26",
      color: isDarkMode ? "white" : "#121212",
      position: "absolute",
      top: 220,
      left: 10,
    },
    text: {
      textAlign: "center",
      overflow: "hidden",
      maxWidth: "200px",
      wordBreak: "break-word",
    },
    textField: {
      width: "80%",
      display: "flex",
      alignSelf: "center",
      justifySelf: "center",
    },
    stack: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "center",
      marginBottom: -40,
    },
  };
};

export default CounterComponent;
