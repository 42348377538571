import { useCallback, useContext, useState } from "react";
import { AppContext } from "../../App";
import { MyStyleSheet } from "../../models";
import ContentContainer from "../ContentContainer";
import Reward from "../../assets/Reward.svg";
import { ClickAwayListener, TextField } from "@mui/material";

interface IRewardsComponentProps {
  rewardText: string;
  joyPoints: number;
  rewardKey: number;
}

const RewardsComponent = ({
  rewardText,
  joyPoints,
  rewardKey,
}: IRewardsComponentProps) => {
  const { isDarkMode } = useContext(AppContext);
  const styles = stylesProvider(isDarkMode);
  const [rewardTextContent, setRewardTextContent] = useState(rewardText);
  const [isTextboxDisplayed, setIsTextboxDisplayed] = useState(false);
  const onClick = useCallback(() => {
    setIsTextboxDisplayed(true);
  }, []);

  return (
    <ContentContainer styles={styles.reward}>
      <img src={Reward} alt={"reward icon"} />

      {isTextboxDisplayed && (
        <ClickAwayListener
          onClickAway={() =>
            setIsTextboxDisplayed(rewardTextContent.length === 0)
          }
        >
          <TextField />
        </ClickAwayListener>
      )}
      <span onClick={onClick}>{`${rewardText}`}</span>
      <span>&nbsp;{`${joyPoints} jp`}</span>
    </ContentContainer>
  );
};

const stylesProvider = (isDarkMode: boolean): MyStyleSheet => {
  return {
    container: {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      height: "fit-content",
      background: "#7ddcff",
      minHeight: 400,
      width: 750,
      position: "absolute",
      top: "10%",
      left: 10,
    },
    reward: {
      background: "white",
      minHeight: 40,
      width: "fit-content",
      display: "flex",
      alignItems: "center",
    },
  };
};

export default RewardsComponent;
